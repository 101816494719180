import { createAction, props } from '@ngrx/store';
import { APIError } from '../../models/error.model';

export enum PageViewsActionsTypes {
    NewPageView = '[Entity Page] New Page View',
    NewPageViewSuccess = '[Page Views API] New Page View Success',
    NewPageViewFailure = '[Page Views API] New Page View Failure'
}

export const newPageView = createAction(
    PageViewsActionsTypes.NewPageView,
    props<{
        payload: {
            url: string;
            item?: string;
            itemType?: string;
            metadata?: object;
        };
    }>()
);
export const newPageViewSuccess = createAction(
    PageViewsActionsTypes.NewPageViewSuccess,
    props<{ payload: any }>()
);
export const newPageViewFailure = createAction(
    PageViewsActionsTypes.NewPageViewFailure,
    props<{ payload: APIError }>()
);


import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ArticleCategory } from '../../models/article-category.model';
import { Article } from '../../models/article.model';
import * as newsActions from '../actions/news.actions';

const selectId = (entity: any) => entity?._id;

export interface CategoryArticles {
    category: ArticleCategory;
    articles: Article[];
    categoryArticles: CategoryArticles[];
}

export const articlesAdapter: EntityAdapter<Article> = createEntityAdapter<Article>({
    selectId
});
export const categoriesAdapter: EntityAdapter<ArticleCategory> = createEntityAdapter<
    ArticleCategory
>({
    selectId
});
export const articlesByCategoryAdapter: EntityAdapter<CategoryArticles> = createEntityAdapter<
    CategoryArticles
>({
    selectId: (entity: CategoryArticles) => entity.category._id
});

export interface NewsState {
    articles: EntityState<Article>;
    categories: EntityState<ArticleCategory>;
    articlesByCategory: EntityState<CategoryArticles>;
}

export const newsInitialState: NewsState = {
    articles: articlesAdapter.getInitialState(),
    categories: categoriesAdapter.getInitialState(),
    articlesByCategory: articlesByCategoryAdapter.getInitialState()
};

export const newsReducer = createReducer(
    newsInitialState,
    on(newsActions.loadArticlesSuccess, (state, { payload }) => ({
        ...state,
        articles: articlesAdapter.addMany(payload, state.articles)
    })),
    on(newsActions.loadCategoryArticlesSuccess, (state, { payload }) => ({
        ...state,
        articles: articlesAdapter.addMany(payload, state.articles)
    })),
    on(newsActions.loadCategoriesSuccess, (state, { payload }) => ({
        ...state,
        categories: categoriesAdapter.addMany(payload, state.categories)
    })),
    on(newsActions.loadArticleSuccess, (state, { payload }) => ({
        ...state,
        articles: articlesAdapter.addOne(payload, state.articles)
    })),
    on(newsActions.updateArticleViewsSuccess, (state, { payload }) => ({
        ...state,
        articles: articlesAdapter.updateOne({ id: payload._id, changes: payload }, state.articles)
    })),
    on(newsActions.loadRecentArticlesByCategorySuccess, (state, { payload }) => ({
        ...state,
        articlesByCategory: articlesByCategoryAdapter.addMany(payload, state.articlesByCategory)
    })),
    on(newsActions.loadArticleOrCategoryArticlesSuccess, (state, { payload }) => ({
        ...state,
        articles: articlesAdapter.addMany(payload.articles, state.articles)
    }))
);


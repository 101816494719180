import { DOCUMENT, isPlatformServer } from '@angular/common';
import {
    Inject,
    Injectable,
    NgZone,
    PLATFORM_ID,
    Renderer2,
    RendererFactory2
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription, combineLatest, filter, take, timeout } from 'rxjs';
import {
    AdvertisementFilterType,
    AdvertisementPlacement,
    AdvertisementPlacementClasses,
    AdvertisementViewModel
} from '../../models/advertisement.model';
import { ScrollService } from '../../shared/services/scroll.service';
import { WindowRef } from '../../shared/services/window.service';
import { trackAdClickEvent, trackAdImpressionEvent } from '../../store/actions/referral.actions';
import {
    selectActiveAdCampaignsAdvertisementsLoadingComplete,
    selectAdvertisementsByPlacementAndFilter
} from '../../store/selectors/referral.selectors';
import { GlobalState } from '../../store/store';
import { SEOService } from './seo.service';

export const AdTags = {
    // news/guide article page
    ARTICLE_VREC_RHS: '23000232604',
    ARTICLE_VREC_LHS: '23000232601',
    ARTICLE_HEADER: '23000230618',
    ARTICLE_INCONTENT_1: '23000232607',
    ARTICLE_INCONTENT_2: '23000232610',
    ARTICLE_INCONTENT_3: '23000232613',
    ARTICLE_INCONTENT_4: '23003782410',
    ARTICLE_FOOTER: '23000232616',

    // news/guides scroller page
    ARTICLES_HOME_VREC_RHS: '23000231254',
    ARTICLES_HOME_VREC_LHS: '23000232586',
    ARTICLES_HOME_HEADER: '23000232583',
    ARTICLES_HOME_INCONTENT_1: '23000232589',
    ARTICLES_HOME_INCONTENT_2: '23000232595',
    ARTICLES_HOME_INCONTENT_3: '23000232598',

    // sticky footer
    APP_STICKY_FOOTER: '23000231242'
};

export const AdTagsVenatus = {
    // Dynamic - 728x90, 970x90, 970x250
    INLINE_DYNAMIC: '6532396783383311e9459961',
    // Static - 728x90
    INLINE_STATIC: '6532397e83383311e9459963',
    // Dynamic - 300x250, 300x600, 160x600
    VERTICAL_DYNAMIC: '65323957ba08b973328790f7',
    // Static - 300x250
    VERTICAL_STATIC: '6532395fba08b973328790f9',

    // Dynamic - 300x250, 336x280, 728x90, 250x250
    INLINE_DESKTOP_FIRST_DYNAMIC: '6532397e83383311e9459963',
    INLINE_DESKTOP_REPEAT_DYNAMIC: '65cb6d0cf1070e4763f1b259',

    // Dynamic - 300x250, 320x100, 300x100, 300x50, 320x50, 160x600, 300x600
    INLINE_MOBILE_FIRST_DYNAMIC: '65cb6bb00e4d9a0c6f9558d4',
    INLINE_MOBILE_REPEAT_DYNAMIC: '65cb6cdcf1070e4763f1b21d',

    // Desktop Sidebar
    SIDEBAR_FIRST_DYNAMIC: '65323957ba08b973328790f7',
    SIDEBAR_REPEAT_DYNAMIC: '65ca4010b1416d3c10a40eee',

    // Dynamic Mobile
    MOBILE_DYNAMIC: '6532399283383311e9459965',
    TAKEOVER_DESKTOP: '6532396783383311e9459961',
    TAKEOVER_MOBILE: '6532399283383311e9459965',
    // 1x1
    // <div class="vm-placement" data-id="6532399eba08b973328790fb" style="display:none"></div>
    RICH_MEDIA: '6532399eba08b973328790fb'
};

export const AdPlacementVenatusTagMap: {
    [key in AdvertisementPlacement]: { desktop?: string; mobile?: string };
} = {
    [AdvertisementPlacement.ARTICLE_HEADER]: {
        desktop: AdTagsVenatus.TAKEOVER_DESKTOP,
        mobile: AdTagsVenatus.TAKEOVER_MOBILE
    },
    [AdvertisementPlacement.ARTICLE_PREINTRO]: {
        desktop: AdTagsVenatus.INLINE_STATIC,
        mobile: AdTagsVenatus.VERTICAL_STATIC
    },
    [AdvertisementPlacement.ARTICLE_INLINE]: {
        desktop: AdTagsVenatus.INLINE_STATIC,
        mobile: AdTagsVenatus.VERTICAL_DYNAMIC
    },
    [AdvertisementPlacement.ARTICLE_INLINE_FIRST]: {
        desktop: AdTagsVenatus.INLINE_DESKTOP_FIRST_DYNAMIC,
        mobile: AdTagsVenatus.INLINE_MOBILE_FIRST_DYNAMIC
    },
    [AdvertisementPlacement.ARTICLE_INLINE_REPEAT]: {
        desktop: AdTagsVenatus.INLINE_DESKTOP_REPEAT_DYNAMIC,
        mobile: AdTagsVenatus.INLINE_MOBILE_REPEAT_DYNAMIC
    },
    [AdvertisementPlacement.ARTICLE_VERTICAL_STICKY]: {
        desktop: AdTagsVenatus.VERTICAL_DYNAMIC
    },
    [AdvertisementPlacement.ARTICLE_VERTICAL_STICKY_FIRST]: {
        desktop: AdTagsVenatus.SIDEBAR_FIRST_DYNAMIC
    },
    [AdvertisementPlacement.ARTICLE_VERTICAL_STICKY_REPEAT]: {
        desktop: AdTagsVenatus.SIDEBAR_REPEAT_DYNAMIC
    }
};

const MOBILE_VIEWPORT_WIDTH = 1024;
const LEFT_STICKY_SMALL_VIEWPORT_WIDTH = 1440;
const REQUEST_URL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
const REQUEST_CONFIG: any = {
    method: 'HEAD',
    mode: 'no-cors'
};
const checkAdsBlocked = callback => {
    fetch(REQUEST_URL, REQUEST_CONFIG)
        .then(response => {
            callback(response.redirected); // ads are blocked if request is redirected
        }) // (we assume the REQUEST_URL doesn't use redirections)
        .catch(() => {
            callback(true); // ads are blocked if request fails
        }); // (we do not consider connction problems)
};

@Injectable()
export class AdService {
    private boundTrackClick: any;
    private isMobile: boolean = false;
    private smallNav: boolean = false;
    private stickies: Element[] = [];
    private renderer: Renderer2;
    private stickyPositionSubscription: Subscription;
    private adTransitionSubscription: Subscription;
    private canInsertAdsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private removablePlacements: any[] = [];
    private permanentPlacements: any[] = [];

    public canInsertAds$ = this.canInsertAdsSubject.asObservable();

    private initIntersectionObserver(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        const config = { childList: true, subtree: true };
        const observer = new MutationObserver(() => this.observeElements());
        observer.observe(document, config);
    }

    private pushProsperFunction(fn: Function) {
        this.window.nativeWindow.__VM.push(fn);
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object,
        private window: WindowRef,
        private zone: NgZone,
        private scrollService: ScrollService,
        private store: Store<GlobalState>,
        private renderer2: RendererFactory2,
        private seoService: SEOService,
        private router: Router
    ) {
        this.window.nativeWindow.__VM = this.window.nativeWindow.__VM || [];
        this.renderer = renderer2.createRenderer(null, null);
        this.boundTrackClick = this.trackClick.bind(this);
    }

    private observeElements(): void {
        let stickies: Element[] = Array.from(
            this.window.nativeDocument.querySelectorAll('.vm-skin-left, .vm-skin-right')
        );

        if (stickies.length) {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            };

            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    // Implement your logic here
                    const element = this.stickies.find((s: Element) => s.isEqualNode(entry.target));
                    if (entry.isIntersecting) {
                        // The element is in view
                        if (element == null) {
                            this.stickies.push(entry.target);
                        } else {
                            // this.repositionLeftStickyElement();
                        }
                        // this.zone.runOutsideAngular(() => {
                        //     entry.target.addEventListener(
                        //         'wheel',
                        //         event => event.preventDefault(),
                        //         { passive: false }
                        //     );
                        // });
                    } else {
                        // The element is not in view
                        const index = this.stickies.findIndex((s: Element) =>
                            s.isEqualNode(entry.target)
                        );
                        this.stickies.splice(index, 1);
                    }
                });
            }, options);

            stickies.forEach(sticky => observer.observe(sticky));
        }
    }
    private transitionPlacements(className: string = 'custom-ad-container') {
        let placements = Array.from(this.document.querySelectorAll(`.${className}`));
        placements = placements.filter(p => p.getAttribute('data-type') === 'custom');
        this.setPlacementsDataId(placements);
        // console.log(
        //     `Transitioning ${changedPlacements.length} placements with class ${className}.`,
        //     placements
        // );
    }

    private setPlacementsDataId(elements: Element[] | NodeList = []) {
        elements.forEach(element => {
            const mobileId = element.getAttribute('data-id-mobile');
            const desktopId = element.getAttribute('data-id-desktop');
            const currentId = element.getAttribute('data-id');

            if (mobileId && this.isMobile) {
                element.setAttribute('data-id', mobileId);
                element.setAttribute('style', '--image-path: url(' + mobileId + ');');
            } else if (desktopId && !this.isMobile) {
                element.setAttribute('data-id', desktopId);
                element.setAttribute('style', '--image-path: url(' + desktopId + ');');
            } else if (!currentId) {
                console.warn('No data-id found for element: ', element);
            }
        });
    }

    private bindAdTransitionEvents() {
        this.adTransitionSubscription = this.window.width$.subscribe(width => {
            if (width <= MOBILE_VIEWPORT_WIDTH && !this.isMobile) {
                this.isMobile = width <= MOBILE_VIEWPORT_WIDTH;
                this.transitionPlacements();
            } else if (width > MOBILE_VIEWPORT_WIDTH && this.isMobile) {
                this.isMobile = width <= MOBILE_VIEWPORT_WIDTH;
                this.transitionPlacements();
            }

            if (width <= LEFT_STICKY_SMALL_VIEWPORT_WIDTH) {
                this.smallNav = true;
            } else {
                this.smallNav = false;
            }

            // this.repositionLeftStickyElement();
        });
    }

    private isPlacementPresent(element: Element) {
        return this.permanentPlacements.some(p => p.getAttribute('id') === element.getAttribute('id'));
    }

    public configProsperAd(element: Element, placement?: AdvertisementPlacement) {
        let id = element.getAttribute('id');
        if (!id) {
            id =
                'placement-' +
                Math.random()
                    .toString(36)
                    .substr(2, 9);
            element?.setAttribute('id', id);
        }
        let ad;

        console.log('[Prosper] Configuring ad for placement: ', placement, ' with element: ', element);

        switch (placement) {
            case AdvertisementPlacement.ARTICLE_HEADER:
                if (this.isPlacementPresent(element)) {
                    return;
                }
                ad = function (admanager, scope) {
                    scope.Config.buildPlacement(configBuilder => {
                        configBuilder.add('desktop_takeover');
                        configBuilder.addDefaultOrUnique('mobile_banner').setBreakPoint('mobile');
                    }).display(id);
                };
                this.permanentPlacements.push(element);
                break;
            case AdvertisementPlacement.ARTICLE_PREINTRO:
                if (this.isPlacementPresent(element)) {
                    return;
                }
                ad = function (admanager, scope) {
                    scope.Config.get('mobile_takeover').display(id);
                };
                this.permanentPlacements.push(element);

                break;
            case AdvertisementPlacement.ARTICLE_INLINE_FIRST:
                element?.classList.add('video-ad');
                element?.parentElement?.classList.add('ad-container-video');
                ad = function (admanager, scope) {
                    scope.Config.get('video').display(id);
                };
                this.removablePlacements.push(element);
                break;
            case AdvertisementPlacement.ARTICLE_INLINE_REPEAT:
                ad = function (admanager, scope) {
                    scope.Config.buildPlacement(configBuilder => {
                        configBuilder.add('leaderboard');
                        configBuilder.addDefaultOrUnique('double_mpu').setBreakPoint('mobile');
                    }).display(id);
                };
                this.removablePlacements.push(element);
                break;
            case AdvertisementPlacement.ARTICLE_VERTICAL_STICKY_FIRST:
            case AdvertisementPlacement.ARTICLE_VERTICAL_STICKY_REPEAT:
                ad = function (admanager, scope) {
                    scope.Config.get('double_mpu').display(id);
                };
                this.removablePlacements.push(element);
                break;

            default:
                console.warn('No ad found for placement: ', placement);
                return;
        }
        this.pushProsperFunction(ad);
    }

    public configProsperStickies() {
        console.log('[Prosper] Configuring stickies.');
        this.pushProsperFunction(function (admanager, scope) {
            // For Horizontal_sticky:
            scope.Config.get('horizontal_sticky').display({ body: true }).setKnown('hs');
        });
        this.pushProsperFunction(function (admanager, scope) {
            // For Mobile Horizontal_sticky:
            scope.Config.get('mobile_horizontal_sticky').display({ body: true }).setKnown('mhs');
        });
        this.pushProsperFunction(function (admanager, scope) {
            // For Vertical Sticky(slightly different):
            scope.Config.verticalSticky().display();
        });
        this.pushProsperFunction(function (admanager, scope) {
            // For interstitial:
            scope.Config.get('gpt_interstitial').display({ body: true }).setKnown('gi');
        });
    }

    public removeAllProsperAds() {
        console.log('[Prosper] Removing all placements.');
        while (this.removablePlacements.length > 0) {
            let placement = this.removablePlacements.pop();
            console.log('[Prosper] Removing placement: ', placement);
            this.pushProsperFunction(function (admanager, scope) {
                admanager.removePlacementByNode(placement);
            });
        }
    }

    public destroyProsperStickies() {
        this.pushProsperFunction(function (admanager, scope) {
            // For Horizontal_sticky:
            scope.Config.getKnown('hs').remove();
        });
        this.pushProsperFunction(function (admanager, scope) {
            // For Mobile Horizontal_sticky:
            scope.Config.getKnown('mhs').remove();
        });
        this.pushProsperFunction(function (admanager, scope) {
            // For Vertical Sticky(slightly different):
            scope.Config.verticalSticky().destroy();
        });
        this.pushProsperFunction(function (admanager, scope) {
            // For interstitial:
            scope.Config.getKnown('gi').remove();
        });
    }

    public setupProsperPageSession() {
        this.pushProsperFunction(function (admanager, scope) {
            const handleNavigated = () => {
                console.log('[Prosper] New page session.');
                scope.Instances.pageManager.newPageSession();
            };

            if (!scope.Instances.pageManager.has('navigated', handleNavigated)) {
                scope.Instances.pageManager.on('navigated', handleNavigated, false);
            }

            // scope.Instances.pageManager.on(
            //     'navigated',
            //     () => {
            //         scope.Instances.pageManager.newPageSession();
            //     },
            //     false
            // );
        });
    }
    
    public newProsperSession() {
        this.pushProsperFunction((admanager, scope) => {
            scope.Instances.pageManager.on(
                'navigated',
                () => {
                    scope.Instances.pageManager.newPageSession();
                },
                false
            );
        });
    }

    private setAdAttributes(
        element: Element,
        ad: AdvertisementViewModel,
        item?: string,
        itemType?: string
    ): Element | void {
        const placement: AdvertisementPlacement = element.getAttribute('data-placement') as any;
        if (!placement) {
            return console.warn(
                'No placement attribute found on element. Ad cannot be initialized. ',
                element
            );
        }
        const parentEl = this.window.nativeDocument.createElement('div');
        parentEl.classList.add('ad-spot');
        parentEl.classList.add('custom-ad-container');
        const a = this.window.nativeDocument.createElement('a');
        a.classList.add('ad-link');
        a.classList.add('ad-spot');
        AdvertisementPlacementClasses[placement].forEach(c => a.classList.add(c));
        a.href = ad.url;
        a.target = '_blank';
        a.rel = 'nofollow';
        element.setAttribute('aria-label', 'Advertisement');
        parentEl.setAttribute('aria-label', 'Advertisement');
        element.setAttribute('data-ad-id', ad._id);
        parentEl.setAttribute('data-ad-id', ad._id);
        element.setAttribute('data-type', 'custom');
        parentEl.setAttribute('data-type', 'custom');
        if (ad.desktopItem) {
            a.classList.add('desktop');
            parentEl.setAttribute('data-id', ad.desktopItem.imagePath);
            parentEl.setAttribute('data-id-desktop', ad.desktopItem.imagePath);
        } else {
            console.warn('No desktop item found for ad. Resorting to Venatus.');
            // this.setVenatusAdAttributes(element, 'desktop');
        }
        if (ad.mobileItem) {
            a.classList.add('mobile');
            parentEl.setAttribute('data-id-mobile', ad.mobileItem.imagePath);
        } else {
            console.warn('No mobile item found for ad. Resorting to Venatus.');
            // this.setVenatusAdAttributes(element, 'mobile');
        }
        parentEl.setAttribute(
            'style',
            '--image-path: url(' +
            (this.isMobile ? ad.mobileItem?.imagePath : ad.desktopItem?.imagePath) +
            ');'
        );

        element.setAttribute('data-item', item || '');
        parentEl.setAttribute('data-item', item || '');
        element.setAttribute('data-item-type', itemType || '');
        parentEl.setAttribute('data-item-type', itemType || '');
        a.appendChild(parentEl);
        element.parentElement?.prepend(a);
    }

    private bindStickyPositioningEvents() {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        let footer = this.window.nativeDocument.querySelector('#footer');
        const makeStickyScroll = 270;
        const defaultTopOffset = 345;
        const stickyScrollOffset = 80;
        const defaultPaddingBetweenElements = 20;

        this.stickyPositionSubscription = this.scrollService.onScroll.subscribe(scroll => {
            if (!footer) {
                footer = this.window.nativeDocument.querySelector('#footer');
                return;
            }
            const footerTop = footer.getBoundingClientRect().top;
            const windowHeight = this.window.nativeWindow.innerHeight;
            const footerTopToBottom = windowHeight - footerTop;

            this.stickies.forEach((stickyContainer: any) => {
                const stickyHeight = stickyContainer.getBoundingClientRect().height;
                const totalStickyHeight = stickyHeight + stickyScrollOffset;

                if (footerTop < totalStickyHeight + defaultPaddingBetweenElements * 2) {
                    this.renderer.removeClass(stickyContainer, 'unset-bottom');
                    this.renderer.addClass(stickyContainer, 'unset-top');

                    // this.renderer.setStyle(stickyContainer, 'background', 'purple');
                    this.renderer.setStyle(stickyContainer, 'top', 'unset');
                    this.renderer.setStyle(
                        stickyContainer,
                        'bottom',
                        footerTopToBottom + defaultPaddingBetweenElements + 'px'
                    );
                } else {
                    this.renderer.removeClass(stickyContainer, 'unset-top');
                    this.renderer.addClass(stickyContainer, 'unset-bottom');
                    this.renderer.setStyle(stickyContainer, 'bottom', 'unset');
                    // this.renderer.setStyle(stickyContainer, 'background', 'red');

                    if (scroll < makeStickyScroll) {
                        this.renderer.setStyle(
                            stickyContainer,
                            'top',
                            defaultTopOffset - scroll + 'px'
                        );
                        this.renderer.setStyle(stickyContainer, 'position', 'absolute');
                    } else {
                        this.renderer.setStyle(stickyContainer, 'top', stickyScrollOffset + 'px');
                        this.renderer.setStyle(stickyContainer, 'position', 'fixed');
                    }
                }
            });
        });
        this.initIntersectionObserver();
    }

    private unbindStickyPositioningEvent() {
        if (this.stickyPositionSubscription) {
            this.stickyPositionSubscription.unsubscribe();
        }
    }

    private unbindAdTransitionEvents() {
        if (this.adTransitionSubscription) {
            this.adTransitionSubscription.unsubscribe();
        }
    }

    public bindEvents() {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        this.bindAdTransitionEvents();
        this.bindStickyPositioningEvents();
    }

    public unbindEvents() {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        this.unbindAdTransitionEvents();
        this.unbindStickyPositioningEvent();
    }

    public async registerAdPlacement(
        element: Element,
        placement: AdvertisementPlacement,
        adFilter: string,
        item?: string,
        itemType?: string
    ) {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        combineLatest([
            this.store.select(selectActiveAdCampaignsAdvertisementsLoadingComplete),
            this.store.select(
                selectAdvertisementsByPlacementAndFilter(
                    placement,
                    adFilter,
                    AdvertisementFilterType.CATEGORY
                )
            )
        ])
            .pipe(
                filter(([loadingComplete]) => loadingComplete === true),
                timeout(2000),
                take(1)
            )
            .subscribe({
                next: ([loading, ads]) => {
                    element.setAttribute('data-placement', placement);

                    if (ads[placement] != null) {
                        const newElement = this.setAdAttributes(
                            element,
                            ads[placement],
                            item,
                            itemType
                        );
                        this.bindImpressionTracking(element);
                        this.bindClickTracking(element?.parentElement || element);
                    } else {
                        this.configProsperAd(element, placement);
                    }
                    // this.handleAdSetup();
                },
                error: () => {
                    this.configProsperAd(element, placement);
                }
            });
    }

    public async insertInlineAds(adFilter: string, itemId?: string, itemType?: string) {
        const inlineAdsElements = this.document.querySelectorAll('.in-content');
        for (let i = 0; i < inlineAdsElements.length; i++) {
            const element = inlineAdsElements[i];

            await this.registerAdPlacement(
                element,
                i == 0
                    ? AdvertisementPlacement.ARTICLE_INLINE_FIRST
                    : AdvertisementPlacement.ARTICLE_INLINE_REPEAT,
                adFilter,
                itemId,
                itemType
            );
        }
    }

    /**
     * Ad tracking code.
     */
    private trackClick(event: any) {
        const adId = event.target.getAttribute('data-ad-id');
        const item = event.target.getAttribute('data-item');
        const itemType = event.target.getAttribute('data-item-type');

        this.store.dispatch(
            trackAdClickEvent({
                payload: {
                    advertisementId: adId,
                    url: this.router.url,
                    title: this.seoService.getTitle(),
                    item,
                    itemType
                }
            })
        );
        event.target.removeEventListener('click', this.boundTrackClick);
        event.target.removeEventListener('auxclick', this.boundTrackClick);
    }

    private trackImpression(element: Element) {
        const adId = element.getAttribute('data-ad-id') as string;
        const item = element.getAttribute('data-item') as string;
        const itemType = element.getAttribute('data-item-type') as string;

        this.store.dispatch(
            trackAdImpressionEvent({
                payload: {
                    advertisementId: adId,
                    url: this.router.url,
                    title: this.seoService.getTitle(),
                    // metadata: {
                    //     boudingClientRect: element.getBoundingClientRect(),
                    //     window: {
                    //         width: this.window.nativeWindow.innerWidth,
                    //         height: this.window.nativeWindow.innerHeight
                    //     }
                    // },
                    item,
                    itemType
                }
            })
        );
    }

    private bindClickTracking(element: Element) {
        element.addEventListener('click', this.boundTrackClick);
        element.addEventListener('auxclick', this.boundTrackClick);
    }

    private bindImpressionTracking(element: Element): IntersectionObserver {
        const obs = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        observer.unobserve(entry.target);
                        observer.disconnect();
                        this.trackImpression(element);
                    }
                });
            },
            {
                rootMargin: '0px',
                threshold: 1
            }
        );

        obs.observe(element);

        return obs;
    }
}


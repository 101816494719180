import { createAction, props } from '@ngrx/store';
import { ArticleCategory } from '../../models/article-category.model';
import { Article } from '../../models/article.model';
import { APIError } from '../../models/error.model';
import { CategoryArticles } from '../reducers/news.reducer';

export enum ArticlesActionsTypes {
    LoadArticles = '[Dashboard Articles Page] Load Articles',
    LoadArticlesSuccess = '[Dashboard Articles Effects] Load Articles Success',
    LoadArticlesFailure = '[Dashboard Articles Effects] Load Articles Failure',

    LoadArticle = '[Dashboard Article Page] Load Article',
    LoadArticleSuccess = '[Dashboard Articles Effects] Load Article Success',
    LoadArticleFailure = '[Dashboard Articles Effects] Load Article Failure',

    UpdateArticleViews = '[Dashboard Article Page] Update Article Views',
    UpdateArticleViewsSuccess = '[Dashboard Articles Effects] Update Article Views Success',
    UpdateArticleViewsFailure = '[Dashboard Articles Effects] Update Article Views Failure',

    LoadRecentArticlesByCategory = '[Dashboard Articles Page] Load Recent Articles By Category',
    LoadRecentArticlesByCategorySuccess = '[Dashboard Articles Effects] Load Recent Articles By Category Success',
    LoadRecentArticlesByCategoryFailure = '[Dashboard Articles Effects] Load Recent Articles By Category Failure',

    LoadCategoryArticles = '[Dashboard Articles Page] Load Category Articles',
    LoadCategoryArticlesSuccess = '[Dashboard Articles Effects] Load Category Articles Success',
    LoadCategoryArticlesFailure = '[Dashboard Articles Effects] Load Category Articles Failure',

    LoadArticleOrCategoryArticles = '[Dashboard Articles Page] Load Article Or Category Articles',
    LoadArticleOrCategoryArticlesSuccess = '[Dashboard Articles Effects] Load Article Or Category Articles Success',
    LoadArticleOrCategoryArticlesFailure = '[Dashboard Articles Effects] Load Article Or Category Articles Failure',

    LoadCategories = '[Dashboard Articles Page] Load Categories',
    LoadCategoriesSuccess = '[Dashboard Articles Effects] Load Categories Success',
    LoadCategoriesFailure = '[Dashboard Articles Effects] Load Categories Failure'
}

export const loadArticles = createAction(
    ArticlesActionsTypes.LoadArticles,
    props<{
        payload: {
            page: number;
            type?: string;
            pageSize?: number;
            category?: string;
            categoryTier?: number;
            trending?: boolean;
        };
    }>()
);
export const loadArticlesSuccess = createAction(
    ArticlesActionsTypes.LoadArticlesSuccess,
    props<{ payload: Article[] }>()
);
export const loadArticlesFailure = createAction(
    ArticlesActionsTypes.LoadArticlesFailure,
    props<{ payload: APIError }>()
);

export const loadArticle = createAction(ArticlesActionsTypes.LoadArticle);
export const loadArticleSuccess = createAction(
    ArticlesActionsTypes.LoadArticleSuccess,
    props<{ payload: Article }>()
);
export const loadArticleFailure = createAction(
    ArticlesActionsTypes.LoadArticleFailure,
    props<{ payload: APIError }>()
);

export const updateArticleViews = createAction(
    ArticlesActionsTypes.UpdateArticleViews,
    props<{
        payload: {
            articleId: string;
        };
    }>()
);
export const updateArticleViewsSuccess = createAction(
    ArticlesActionsTypes.UpdateArticleViewsSuccess,
    props<{ payload: Article }>()
);
export const updateArticleViewsFailure = createAction(
    ArticlesActionsTypes.UpdateArticleViewsFailure,
    props<{ payload: APIError }>()
);

export const loadRecentArticlesByCategory = createAction(
    ArticlesActionsTypes.LoadRecentArticlesByCategory,
    props<{
        payload?: {
            page?: number;
            pageSize?: number;
            category?: string;
        };
    }>()
);
export const loadRecentArticlesByCategorySuccess = createAction(
    ArticlesActionsTypes.LoadRecentArticlesByCategorySuccess,
    props<{ payload: CategoryArticles[] }>()
);
export const loadRecentArticlesByCategoryFailure = createAction(
    ArticlesActionsTypes.LoadRecentArticlesByCategoryFailure,
    props<{ payload: APIError }>()
);

export const loadCategoryArticles = createAction(
    ArticlesActionsTypes.LoadCategoryArticles,
    props<{
        payload?: {
            page?: number;
            pageSize?: number;
            category?: string;
        };
    }>()
);
export const loadCategoryArticlesSuccess = createAction(
    ArticlesActionsTypes.LoadCategoryArticlesSuccess,
    props<{ payload: Article[] }>()
);
export const loadCategoryArticlesFailure = createAction(
    ArticlesActionsTypes.LoadCategoryArticlesFailure,
    props<{ payload: APIError }>()
);

export const loadArticleOrCategoryArticles = createAction(
    ArticlesActionsTypes.LoadArticleOrCategoryArticles,
    props<{
        payload?: {
            page?: number;
            pageSize?: number;
            category?: string;
        };
    }>()
);
export const loadArticleOrCategoryArticlesSuccess = createAction(
    ArticlesActionsTypes.LoadArticleOrCategoryArticlesSuccess,
    props<{
        payload: {
            type: 'article' | 'category-articles';
            articles: Article[];
        };
    }>()
);
export const loadArticleOrCategoryArticlesFailure = createAction(
    ArticlesActionsTypes.LoadArticleOrCategoryArticlesFailure,
    props<{ payload: APIError }>()
);

export const loadCategories = createAction(
    ArticlesActionsTypes.LoadCategories,
    props<{
        payload?: {
            page: number;
            pageSize?: number;
            categoryTier?: number;
            trending?: boolean;
            featured?: boolean;
        };
    }>()
);
export const loadCategoriesSuccess = createAction(
    ArticlesActionsTypes.LoadCategoriesSuccess,
    props<{
        payload: ArticleCategory[];
    }>()
);
export const loadCategoriesFailure = createAction(
    ArticlesActionsTypes.LoadCategoriesFailure,
    props<{ payload: APIError }>()
);


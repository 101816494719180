import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AnchorLinkService {
    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private router: Router,
        @Inject(DOCUMENT) private document: Document
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe(() => this.updateLinks());
        }
    }

    public bind(): void {
        setTimeout(() => this.updateLinks(), 0);
    }

    public updateLinks(): void {
        setTimeout(() => {
            const links = Array.from(this.document.getElementsByTagName('a'));
            links.forEach((link: any) => {
                if (link?.getAttribute('href')?.startsWith('#')) {
                    const url = this.router.url.split('#')[0];
                    const fragment = link.getAttribute('href').substring(1);
                    link.setAttribute('href', `${url}#${fragment}`);
                    link.removeEventListener('click', link.listenerFn);
                    link.listenerFn = (event: Event) => {
                        event.preventDefault();
                        const element = this.document.getElementById(fragment);
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth' });
                        }
                    };
                    link.addEventListener('click', link.listenerFn);
                }
            });
        }, 0);
    }
}


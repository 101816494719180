import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, share, throttleTime } from 'rxjs/operators';
import { GlobalState } from '../../store/store';
import { WindowRef } from './window.service';

const BUFFER_TIME = 0;
const DEBOUNCE_TIME = 180;
const THROTTLE_TIME = 180;

@Injectable()
export class ScrollService {
    private _subj: Subject<number> = new Subject();
    private boundSet = new Set<EventTarget>();
    private handler: EventListener;

    /**
     * Throttled Scroll Event of bound targets
     */
    public onScroll: Observable<number>;

    /**
     * Emits when the scrolling is started on bound targets
     */
    public onScrollStart: Observable<number>;

    /**
     * Emits when the scrolling is finished on bound targets
     */
    public onScrollEnd: Observable<number>;

    private static _handler(this: ScrollService, e: any): void {
        this._subj.next(e.target?.scrollingElement?.scrollTop || 0);
    }

    constructor(
        private window: WindowRef,
        private zone: NgZone,
        private store: Store<GlobalState>
    ) {
        this.handler = ScrollService._handler.bind(this);
        this.onScroll = this._subj.pipe(throttleTime(BUFFER_TIME), share());
        this.onScrollEnd = this._subj.pipe(debounceTime(DEBOUNCE_TIME), share());
        this.onScrollStart = this._subj.pipe(throttleTime(THROTTLE_TIME), share());
        this.bind(this.window.nativeWindow);
    }

    /**
     * Binds its listener to the event target
     * to trigger checking position of in-view directive
     * or for emiting its scroll events.
     * Returns the unbinding function
     */
    public bind(target: EventTarget, customHandler?: EventListenerOrEventListenerObject) {
        this.zone.runOutsideAngular(() => {
            if (!this.boundSet.has(target)) {
                target.addEventListener('scroll', customHandler || this.handler, { passive: true });
                this.boundSet.add(target);
            }
        });
        return this.unbind.bind(this, target);
    }

    /**
     * Removes its listener from the target
     */
    public unbind(target: EventTarget, customHandler?: EventListenerOrEventListenerObject): void {
        this.boundSet.delete(target);
        target.removeEventListener('scroll', customHandler || this.handler);
    }
}

